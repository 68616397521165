<template>
  <div rounded="7.5" flex="~ items-center">
    <BaseAZPopover v-model:open="showCityPopup" w="full" :show-close-icon="false" align="start" :align-offset="alignOffset" :side-offset="sideOffset">
      <template #reference>
        <slot :select-city-name="selectCityName">
          <div v-bind="$attrs" flex="~ items-center" p="x-3 y-4">
            <div class="text-3xl" :class="[selectCityName ? 'i-local-location' : 'i-local-search']" text="#8D8D8D"></div>
            <div m="l-3.5" flex="1" text="left">
              <span v-if="selectCityName" text="lg black" font="500">{{ selectCityName }}</span>
              <span v-else text="lg #B1B1B1">Where are you going?</span>
            </div>
            <div v-if="selectCityName" class="i-local-close" text="base" @click.stop="filterConditionStore.filterConditions.selectedCity = {}"></div>
          </div>
        </slot>
      </template>
      <DesktopSearchCity :refrehs-on-confirm="false" @confirm="showCityPopup = false"></DesktopSearchCity>
    </BaseAZPopover>
  </div>
</template>

<script setup lang="ts">
defineProps({
  alignOffset: {
    type: Number,
    default: 5,
  },
  sideOffset: {
    type: Number,
    default: 5,
  },
  refrehsOnConfirm: {
    type: Boolean,
    default: false,
  },
  pageType: {
    type: String,
    default: '',
  },
})

const filterConditionStore = useFilterConditionsStore()
const { showCityPopup } = useUseHomePopup()

const el = ref<HTMLElement | null>(null)
const { y } = useScroll(el)

const selectCityName = computed(() => {
  return filterConditionStore.filterConditions.selectedCity?.destinationName
})
const continent = ref('Popular destincations')

watch(continent, () => {
  nextTick(() => {
    y.value = 0
  })
})
</script>

<style lang="scss" scoped>
.city-item {
  cursor: pointer;
  &:hover {
    color: var(--tc-color-primary1);
  }
}
</style>
