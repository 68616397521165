<template>
  <div class="px-10 -mt-10">
    <div flex="~ items-center justify-between" rounded="5" bg="white" p="x-4" class="shadow-[0_2px_8px_0px_rgba(122,122,122,0.25)] lg:h-20">
      <div flex="~ items-center col  lg:row 1" p="x-4 lg:0" h="full">
        <!-- 选择地址 -->
        <DesktopSearchDestination flex="1" w="full" :side-offset="16" :align-offset="-16" page-type="home" />
        <div class="hidden lg:block" w="0.25" m="x-4" bg="#B3B3B3" h="8" flex="shrink-0"></div>
        <div flex="1 ~ items-center" h="full" w="full" p="b-1 lg:b-0">
          <!-- 选择日期 -->
          <div flex="1 lg:initial">
            <DesktopSelectDate class="h-full" page-type="home">
              <template #default="{ formatDate }">
                <div p="3" cursor="pointer" rounded="xl" flex="~ items-center shrink-0">
                  <img :width="32" :height="32" src="~assets/icons/icon-calendar.png" />
                  <div
                    class="truncate text-lg"
                    :style="{
                      fontWeight: formatDate ? 500 : 400,
                      color: formatDate ? '#1B1B1B' : '#B1B1B1',
                    }"
                    m="l-4"
                  >
                    {{ formatDate || 'Select your dates' }}
                  </div>
                </div>
              </template>
            </DesktopSelectDate>
          </div>
          <!-- <div w="0.25" m="x-4" bg="#b3b3b3" h="8"></div> -->
          <!-- 选择门票类型 -->
          <!-- <div flex="1 lg:initial">
            <BusinessBSelectTicket page-type="home">
              <template #default="{ checkedLabel }">
                <div p="3" class="flex cursor-pointer items-center rounded-xl hover:bg-[#F0F0F0]">
                  <img :width="32" :height="32" src="~assets/icons/icon-ticket.png" class="flex-shrink-0" />
                  <div class="truncate" font="500" text="xl #1B1B1B" m="l-4" max-w="70 lg:45">
                    {{ checkedLabel }}
                  </div>
                </div>
              </template>
            </BusinessBSelectTicket>
          </div> -->
        </div>
      </div>
      <div
        m="l-4 y-3"
        p="x-7 y-4"
        cursor="pointer"
        class="block rounded-4 bg-black text-center"
        @click="handleSearch"
      >
        <div font="500" text="lg white">
          Search
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const emit = defineEmits(['search'])
const { handleHomeSearch } = useUseHomePopup()

function handleSearch() {
  handleHomeSearch()
  emit('search')
}
</script>

<style>

</style>
